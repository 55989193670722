import React from "react";
import { Link } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import Footer from "../../components/Footer";
import SectionHeader from "../../components/SectionHeader";
import baseLogo from "../../images/logo.png";

const FrontPage = () => {
  const session = useSelector(state => state.auth.session);
  const logo = session?.options?.logo ?? baseLogo;

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <SectionHeader logo={logo} />

      <main className="flex flex-col p-4 md:p-8 space-y-8">
        <section className="flex flex-col md:flex-row items-center md:items-start lg:p-8 mb-8 space-y-4 md:space-y-0 md:space-x-4">
          <div className="md:order-1 flex flex-col md:w-1/2 p-4 space-y-4">
            <h1 className="text-3xl md:text-4xl font-medium">
              Grow with powerful mobile first B2B e-commerce platform.
            </h1>
            <p className="text-base md:text-lg text-gray-600">
              B2B e-commerce is at an inflection point. By 2020, the size of U.S
              B2B eCommerce market is expected to be twice that of B2C
              eCommerce. Businesses are fast shifting to B2B eCommerce but
              customer expectations are also higher than ever. BharatMart is a
              SaaS platform for running a B2B Business.
            </p>
          </div>
          <div className="md:order-2 relative w-full md:w-1/2 flex flex-col items-center">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/c_scale,q_100,w_680/v1540286789/websiteData/illustration_3x.png"
              }
              className="w-auto object-fit max-h-80"
            />
          </div>
        </section>

        <section className="flex flex-col md:flex-row items-center md:items-start lg:p-8 mb-8 space-y-4 md:space-y-0 md:space-x-4">
          <div className="md:order-2 flex flex-col md:w-1/2 p-4 space-y-4">
            <h1 className="text-3xl md:text-3xl font-medium">Mobile First</h1>
            <p className="text-base md:text-lg text-gray-600">
              Business buyers are also consumers who have experienced B2C
              ecommerce and they expect the same convenient shopping experience
              in their B2B ecommerce platform.
              <span className="block text-brown">
                Mobile first and an amazon like shopping experience.
              </span>{" "}
            </p>

            <p className="text-base md:text-lg text-gray-600">
              In BharatMart, you can use ALL the features from your mobile app.
              Built for a mobile first world.
            </p>
            <Link to="/feature-list">
              <button className="w-full md:w-48 px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-base">
                Learn More
              </button>
            </Link>
          </div>
          <div className="md:order-1 relative w-full md:w-1/2 flex flex-col items-center">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/v1540163681/websiteData/mobile-first_3x.png"
              }
              className="w-auto object-fit max-h-80"
            />
          </div>
        </section>

        <section className="flex flex-col md:flex-row items-center md:items-start lg:p-8 mb-8 space-y-4 md:space-y-0 md:space-x-4">
          <div className="md:order-1 flex flex-col md:w-1/2 p-4 space-y-4">
            <h1 className="text-3xl md:text-3xl font-medium">
              All the complicated B2B use cases
            </h1>
            <p className="text-base md:text-lg text-gray-600">
              B2B ecommerce is a lot more complicated than B2C ecommerce.
              <span className="text-green-600">
                Multi-tier distribution selling, geo-fencing, advanced pricing
                rules, fully customizable order and payment flows across all
                layers of supply chain, on the ground logistics and sales
                management etc.
              </span>
              With BharatMart you can manage all the complex B2B business
              requirements effortlessly.
            </p>
            <Link to="/feature-list">
              <button className="w-full md:w-48 px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-base">
                Learn More
              </button>
            </Link>
          </div>
          <div className="md:order-2 relative w-full md:w-1/2 flex flex-col items-center">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/v1540159281/websiteData/b2b_3x.png"
              }
              className="w-auto object-fit max-h-80"
            />
          </div>
        </section>

        <section className="flex flex-col md:flex-row items-center md:items-start lg:p-8 mb-8 space-y-4 md:space-y-0 md:space-x-4">
          <div className="md:order-2 flex flex-col md:w-1/2 p-4 space-y-4">
            <h1 className="text-3xl md:text-3xl font-medium">
              Developer First
            </h1>
            <p className="text-base md:text-lg text-gray-600">
              Commerce is essentially movement of goods from A to B and movement
              of payment in the opposite direction. But between A and B, it can
              take varied forms (marketplace, own inventory, drop shipping etc.
            </p>
            <p className="text-base md:text-lg text-brown">
              In BharatMart, you can use ALL the features from your mobile app.
              Built for a mobile first world.
            </p>
            <Link to="/developer-first">
              <button className="w-full md:w-48 px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-base">
                Learn More
              </button>
            </Link>
          </div>
          <div className="md:order-1 relative w-full md:w-1/2 flex flex-col items-center">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/v1540163681/websiteData/mobile-first_3x.png"
              }
              className="w-auto object-fit max-h-80"
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default FrontPage;
