import React, { useEffect, useState } from "react";
import { Link } from "react-horizontal-router-dom";

const SectionHeader = ({ logo }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (isMobileMenuOpen && !event.target.closest("nav")) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === "Escape" && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => document.removeEventListener("keydown", handleEscKey);
  }, [isMobileMenuOpen]);

  return (
    <header className="sticky top-0 z-50 bg-white/95 backdrop-blur-sm border-b border-gray-100">
      <div className="max-w-7xl mx-auto">
        <nav className="flex justify-between items-center px-4 md:px-8 py-4">
          <div className="flex items-center">
            <Link
              to="/"
              className="relative w-10 h-10 md:w-14 md:h-14 transition-transform hover:scale-105 duration-300">
              <img
                src={logo}
                alt="Company Logo"
                className="w-full h-full object-contain rounded-lg"
              />
            </Link>

            <div className="hidden md:block ml-6">
              <Link to="/feature-list">
                <button className="group px-5 py-2.5 border-2 border-purple-600 rounded-full text-purple-600 hover:bg-purple-600 hover:text-white transition-all duration-300 font-medium text-sm relative overflow-hidden">
                  <span className="relative z-10">Features</span>
                  <div className="absolute inset-0 bg-purple-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
                </button>
              </Link>
            </div>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <Link to="/auth">
              <button className="group px-6 py-2.5 border-2 border-purple-600 rounded-full text-purple-600 hover:text-white transition-all duration-300 font-medium relative overflow-hidden">
                <span className="relative z-10">Login</span>
                <div className="absolute inset-0 bg-purple-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
              </button>
            </Link>
            <Link to="/auth/signup">
              <button className="px-6 py-2.5 bg-purple-600 border-2 border-purple-600 rounded-full text-white hover:bg-purple-700 hover:border-purple-700 transition-colors duration-300 font-medium shadow-md hover:shadow-lg">
                Sign Up
              </button>
            </Link>
          </div>

          <div className="flex md:hidden items-center space-x-3">
            <Link to="/feature-list">
              <button className="group px-4 py-2 border-2 border-purple-600 rounded-full text-purple-600 hover:bg-purple-600 hover:text-white transition-all duration-300 font-medium text-sm relative overflow-hidden whitespace-nowrap">
                <span className="relative z-10">Features</span>
                <div className="absolute inset-0 bg-purple-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
              </button>
            </Link>

            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
              aria-label="Toggle mobile menu"
              aria-expanded={isMobileMenuOpen}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                {isMobileMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </nav>

        <div
          className={`md:hidden px-4 pb-4 transform transition-all duration-300 ease-in-out ${
            isMobileMenuOpen
              ? "opacity-100 translate-y-0"
              : "opacity-0 -translate-y-4 pointer-events-none hidden"
          }`}>
          <div className="flex flex-col space-y-3 bg-white rounded-lg p-4 shadow-lg">
            <Link to="/auth">
              <button className="w-full px-6 py-2.5 border-2 border-purple-600 rounded-full text-purple-600 hover:bg-purple-600 hover:text-white transition-colors duration-300 font-medium">
                Login
              </button>
            </Link>
            <Link to="/auth/signup">
              <button className="w-full px-6 py-2.5 bg-purple-600 border-2 border-purple-600 rounded-full text-white hover:bg-purple-700 hover:border-purple-700 transition-colors duration-300 font-medium shadow-md">
                Sign Up
              </button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SectionHeader;
